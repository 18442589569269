export default {
  blockchain: 'eos',
  protocol: process.env.REACT_APP_EOS_PROTO,
  host: process.env.REACT_APP_EOS_HOST,
  port: process.env.REACT_APP_EOS_PORT,
  chainId: process.env.REACT_APP_EOS_CHAINID,
  contract: process.env.REACT_APP_EOS_CONTRACT,
  userstringtable: process.env.REACT_APP_EOS_USERSTRINGTABLE,
  lettertable: process.env.REACT_APP_EOS_LETTERTABLE
}
