export const LETTER_MODAL_OPEN = 'LETTER_MODAL_OPEN';
export const LETTER_MODAL_CLOSE = 'LETTER_MODAL_CLOSE';
export const STRING_MODAL_OPEN = 'STRING_MODAL_OPEN';
export const STRING_MODAL_CLOSE = 'STRING_MODAL_CLOSE';
export const STRING_MODAL_SET_STRING = 'STRING_MODAL_SET_STRING';
export const STRING_MODAL_SET_CARET_POSITION = 'STRING_MODAL_SET_CARET_POSITION';
export const HOW_TO_MODAL_OPEN = 'HOW_TO_MODAL_OPEN';
export const HOW_TO_MODAL_CLOSE = 'HOW_TO_MODAL_CLOSE';

export const SCATTER_LOAD = 'SCATTER_LOAD';
export const SCATTER_RECEIVE_LOGIN_INFO = 'SCATTER_RECEIVE_LOGIN_INFO';
export const SCATTER_REQUEST_BUY_LETTER = 'SCATTER_REQUEST_BUY_LETTER';
export const SCATTER_FINISH_BUY_LETTER = 'SCATTER_FINISH_BUY_LETTER';
export const SCATTER_REQUEST_LOGIN = 'SCATTER_REQUEST_LOGIN';
export const SCATTER_FINISH_LOGIN = 'SCATTER_FINISH_LOGIN';
export const SCATTER_REQUEST_SET_USER_STRING = 'SCATTER_REQUEST_SET_USER_STRING';
export const SCATTER_FINISH_SET_USER_STRING = 'SCATTER_FINISH_SET_USER_STRING';
export const SCATTER_CLEAR_ERROR = 'SCATTER_CLEAR_ERROR';

export const EOS_REQUEST_LETTERS = 'EOS_REQUEST_LETTERS';
export const EOS_REQUEST_STRINGS = 'EOS_REQUEST_STRINGS';
export const EOS_RECEIVE_LETTERS = 'EOS_RECEIVE_LETTERS';
export const EOS_RECEIVE_STRINGS = 'EOS_RECEIVE_STRINGS';
