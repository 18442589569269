import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closeHowToModal } from '../actions/modalActions'

export class HowToModal extends Component {
  render() {
    return (
      <div id="how-to" className={"modal" + (this.props.howToModal !== null ? " is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">How To Play</p>
            <button className="delete" aria-label="close" onClick={ () => this.props.closeHowToModal()}></button>
          </header>
          <section className="modal-card-body">
            <div className="content">
              <ol>
                  <li>
                    Ensure you have an EOS account.  If you need to create an EOS account, you can follow the instructions <a href="https://eos-account-creator.com/">here</a>.
                  </li>
                  <li>
                  Download and install the EOS wallet <a style={{ fontWeight: 'bold' }} href="https://get-scatter.com">Scatter</a>.  This wallet application is used for interacting with EOS dApps.  Now you are ready to login by clicking the blue <i style={{ color: 'blue'}}>Login to Scatter</i> button on our website.
                  </li>
                  <li>
                  You can buy a character by paying the price displayed next to it.  After you purchase that character, its price will increase by 35% and could then be purchased by someone else.
                  </li>
                  <li>
                  Once you have a character, you can use that character in any string you decide to post for the others to see.  The string length is limited to 40 characters in length.  It can contain any of the characters you purchased plus the space character.
                  </li>
                  <li>
                  If one of the characters you own is purchased by another user, you would receive <strong>the full price you had paid for it plus 30%</strong>.  The remaining 5% is paid to the contract.  If you were using that character in your string, it will automatically be replaced by a space.
                  </li>
                  <li>
                  You can update your string anytime after you purchase a new character, <strong>OR</strong> anytime after someone else has purchased one of yours, <strong>OR</strong> after 1 minute since the last time you updated your string.
                  </li>
              </ol>
              <strong>Need any help?</strong> Get in touch with us at <a style={{ fontWeight: 'bold' }}  href="mailto:buyachar@gmail.com">buyachar@gmail.com</a> !
            </div>
          </section>
          <footer className="modal-card-foot buttons is-centered">
              <button
                onClick={ () => this.props.closeHowToModal()}
                className="button is-success"
              >
                OK
              </button>
          </footer>
        </div>
      </div>
     )
  }
}

function mapStateToProps(state) {
  return { 
    howToModal: state.howToModal
  }
}

export default connect(mapStateToProps, { closeHowToModal })(HowToModal);
