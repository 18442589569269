import { HOW_TO_MODAL_OPEN, HOW_TO_MODAL_CLOSE } from '../actions/actionTypes'
const defaultState = null;

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case HOW_TO_MODAL_OPEN: {
      return true;
    }

    case HOW_TO_MODAL_CLOSE: {
      return null;
    }

    default:
      return state;
  }
};
