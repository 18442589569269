import { LETTER_MODAL_OPEN, LETTER_MODAL_CLOSE, STRING_MODAL_OPEN, STRING_MODAL_CLOSE, STRING_MODAL_SET_STRING, STRING_MODAL_SET_CARET_POSITION, HOW_TO_MODAL_OPEN, HOW_TO_MODAL_CLOSE } from "./actionTypes"
import { clearErrorScatter } from './scatterActions'
import { getStringModal } from '../selectors/eosSelectors'

export function openLetterModal(modalProps) {
  return {
    type: LETTER_MODAL_OPEN,
    payload: modalProps
  };
}

export function closeLetterModal() {
  return (dispatch, getState) => {
    dispatch(clearErrorScatter())

    dispatch({
      type: LETTER_MODAL_CLOSE 
    })
  }
}

export function openStringModal(modaluserstring) {
  return {
    type: STRING_MODAL_OPEN,
    payload: modaluserstring
  };
}

export function closeStringModal() {
  return (dispatch, getState) => {
    dispatch(clearErrorScatter())

    dispatch({
      type: STRING_MODAL_CLOSE 
    })
  }
}

export function setStringStringModal(modaluserstring) {
  return {
    type: STRING_MODAL_SET_STRING,
    payload: modaluserstring
  };
}

export function setCaretPositionStringModal(pos) {
  return {
    type: STRING_MODAL_SET_CARET_POSITION,
    payload: pos
  };
}

export function insertCharAtCaretStringModal(chr) {
  return (dispatch, getState) => {
    const stringModal = getStringModal(getState())
    const pos = stringModal.caretPosition
    const str = stringModal.string

    const chrLength = chr.length

    dispatch(setStringStringModal(str.substring(0, pos) + chr + str.substring(pos)))
    dispatch(setCaretPositionStringModal(pos+chrLength))
  }
}

export function openHowToModal() {
  return {
    type: HOW_TO_MODAL_OPEN,
  }
}

export function closeHowToModal() {
  return {
    type: HOW_TO_MODAL_CLOSE 
  }
}
