import { STRING_MODAL_OPEN, STRING_MODAL_CLOSE, STRING_MODAL_SET_STRING, STRING_MODAL_SET_CARET_POSITION } from '../actions/actionTypes'
const defaultState = null;

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case STRING_MODAL_OPEN: {
      return { 
        string: action.payload,
        caretPosition: 0
      };
    }

    case STRING_MODAL_CLOSE: {
      return null;
    }

    case STRING_MODAL_SET_STRING: {
      return {
        ...state,
        string: action.payload
      }
    }

    case STRING_MODAL_SET_CARET_POSITION: {
      return { 
        ...state,
        caretPosition: action.payload 
      };
    }

    default:
      return state;
  }
};
