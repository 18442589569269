import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.css';

import { loadEosStrings } from './actions/eosActions'

store.dispatch(loadEosStrings(true))

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
