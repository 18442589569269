import React, { Component } from "react"

import { connect } from 'react-redux'

import network from './env'

import ModalManager from './components/ModalManager'
import UserStringModal from './components/UserStringModal'
import HowToModal from './components/HowToModal'
import { openLetterModal, openStringModal, openHowToModal } from './actions/modalActions'
import { loginScatter } from './actions/scatterActions'
import { loadEosStrings } from './actions/eosActions'

import { 
  getEosLoadingStrings,
  getEosLoadingLetters,
  getEosCharactersIOwn,
  getEosBeforeFirstLettersLoad,
  getEosBeforeFirstStringsLoad
} from './selectors/eosSelectors'

import twemoji from 'twemoji'

import './bc-emoji.css'

const twemojiOptions = {
  folder: 'svg',
  ext: '.svg',
  className: 'bc-emj'
}

class App extends Component {
  render() {

    return (
      <>
        <div className="container">
          <nav className="level is-mobile" id="main-nav" role="navigation" aria-label="main navigation">
            <div className="level-left">
              <div className="level-item has-text-centered">
                <button className={"button is-size-7 is-link" + (this.props.loggingIn ? " is-loading" : "") } onClick={this.props.loginScatter} disabled={ this.props.loggingIn || this.props.username !== null }>
                  <span className="icon">
                    <i className="fas fa-sign-in-alt"></i>
                  </span>
                  <span>Login to Scatter</span>
                </button>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item has-text-centered">
                <button className="button is-size-7 is-success" color='green' onClick={() => this.props.openHowToModal()}><span className="icon"><i className="fas fa-question-circle"></i></span><span>How to Play</span></button>
              </div>
            </div>
          </nav>
          <main className="section">
            <header className="has-text-centered" size="small">
              <h1 className="title is-1">
                BuyAChar.com
                <span className="icon" onClick={ () => { this.props.loadEosStrings(true);} }>
                  <i className="top-icon fas fa-sync" size='small' style={{ marginLeft: '0.5em' }} title="Refresh"></i>
                </span>
                <span className="icon" onClick={ () => this.buyc.scrollIntoView() }>
                  <i className="top-icon fas fa-angle-down" size='small' style={{ marginLeft: '0.5em' }} title="Skip to characters"></i>
                </span>
              </h1>
            </header>
            <div className="message is-danger" hidden={this.props.scatterloginerror === null}>
              <div className="message-header">Scatter Error</div>
              <div className="message-body">{this.props.scatterloginerror}</div>
            </div>
            <section id="user-string-list" className={"element" + (this.props.eosLoadingStrings ? " is-loading" : "")}>
              { this.props.username !== null ? <button className="button is-info is-fullwidth" onClick={() => this.props.openStringModal(this.props.userstring)} disabled={this.props.username === null || this.props.charactersIOwn.length === 0 } >Update your string</button> : null }
              { this.props.strings.length !== 0 ?
                <ul>
                  {
                    this.props.strings.map(
                      (item, i) =>
                        <li key={i}>
                          <div className="content" style={{padding: '0.25em 0 0.75em 0'}}>
                            <pre className="is-size-6-mobile is-size-5-tablet" dangerouslySetInnerHTML={{__html: twemoji.parse(item.string, twemojiOptions)}} />
                            <div className="has-text-grey" style={{ fontSize: '0.9em' }}><span className="icon"><i className="fas fa-user"></i></span><span>{item.owner}</span></div>
                          </div>
                        </li>
                    )
                  }
                </ul>
                :
                (
                  (this.props.username === null) && (this.props.strings.length === 0) && (this.props.eosBeforeFirstStringsLoad === false) ?
                    <i>Login to Scatter, buy some characters and post something here!</i>
                    :
                    null
                )
              }

            </section>
          {
            this.props.eosBeforeFirstLettersLoad === false ?
            (
            <section className={"has-text-centered element" + (this.props.eosLoadingLetters ? " is-loading" : "")} style={{ marginBottom: '1em'}}>
              <header size="medium">
                <h2 className="title is-2" ref={ el => this.buyc = el }>Buy characters</h2>
              </header>
              <table className="table is-fullwidth">
                <thead>
                  <tr className="has-text-centered">
                    <th className="has-text-centered">
                      Character
                    </th>
                    <th className="has-text-centered">
                      Owner
                    </th>
                    <th className="has-text-centered">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.props.letters.map(
                    (item, i) => 
                      <tr key={i}>
                        <td className="has-text-centered">
                          { (this.props.username !== null && item.owner !== this.props.username) ?
                            <button
                              className="button"
                              onClick={() => this.props.openLetterModal({letter: item.letter})}
                              disabled={this.props.username === null || item.owner === this.props.username}
                            >
                              <span dangerouslySetInnerHTML={{__html: twemoji.parse(String.fromCodePoint(item.letter), twemojiOptions) }} />
                            </button>
                            :
                            <span dangerouslySetInnerHTML={{__html: twemoji.parse(String.fromCodePoint(item.letter), twemojiOptions) }} />
                          }
                        </td>
                        <td className="has-text-centered">
                          <span className={item.owner === this.props.username ? "tag is-success": null}>{item.owner !== network.contract ? item.owner : '-' }</span>
                        </td>
                        <td className="has-text-centered">
                          {item.price}
                        </td>
                      </tr>
                  )
                }
                </tbody>
              </table>
            </section>
            ) : null
          }
            <ModalManager />
            <UserStringModal />
            <HowToModal />
          </main>
        </div>
      {
        this.props.eosBeforeFirstLettersLoad === false ?
        (
        <footer className="footer" style={{padding: '2em 0 5em 0'}}>
          <div className="content has-text-centered" style={{fontSize: '0.95em'}}>&copy; 2020 BuyAChar.com</div>
        </footer>
        ) : null
      }
      </>
    );
  }
}

const getUserString = (username, eosstrings) => {
  if (username !== null) {
    const stringentry = eosstrings.find((item) => item.owner === username)

    if (stringentry) {
      return stringentry.string
    }
  }
  return null
}

const mapStateToProps = (state) => {
  return {
    scatter: state.scatter.scatter,
    username: state.scatter.username,
    loggingIn: state.scatter.loggingIn,
    scatterloginerror: state.scatter.scatterloginerror,
    letters: state.eos.letters,
    strings: state.eos.strings,
    eosLoadingStrings: getEosLoadingStrings(state),
    eosLoadingLetters: getEosLoadingLetters(state),
    userstring: getUserString(state.scatter.username, state.eos.strings) || '',
    charactersIOwn: getEosCharactersIOwn(state) || [],

    eosBeforeFirstStringsLoad: getEosBeforeFirstStringsLoad(state),
    eosBeforeFirstLettersLoad: getEosBeforeFirstLettersLoad(state),
  }
}

export default connect(mapStateToProps, { openStringModal, openLetterModal, openHowToModal, loginScatter, loadEosStrings })(App);
