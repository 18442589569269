import { SCATTER_RECEIVE_LOGIN_INFO, SCATTER_REQUEST_BUY_LETTER, SCATTER_FINISH_BUY_LETTER, SCATTER_REQUEST_LOGIN, SCATTER_FINISH_LOGIN, SCATTER_REQUEST_SET_USER_STRING, SCATTER_FINISH_SET_USER_STRING, SCATTER_CLEAR_ERROR } from "./actionTypes";

import network from '../env'

import { loadEosStrings } from './eosActions'
import { closeLetterModal } from './modalActions'

import ScatterJS from '@scatterjs/core';
import ScatterEOS from '@scatterjs/eosjs2'
import { JsonRpc, Api } from 'eosjs'

export function receiveScatterLoginInfo(username) {
  return {
    type: SCATTER_RECEIVE_LOGIN_INFO,
    payload: username
  }
}

export function loginScatter() {
  return async (dispatch, getState) => {
    dispatch(requestLoginScatter())

    try {
      ScatterJS.plugins( new ScatterEOS() )
      let connected = await ScatterJS.scatter.connect('BuyAChar.com', { initTimeout: 3000 })
      if(!connected) {
        throw new Error('Could not find Scatter')
      }

      const requiredFields = { accounts: [network] }
      await ScatterJS.login(requiredFields)
      const account = ScatterJS.account('eos')

      dispatch(receiveScatterLoginInfo(account.name))

      dispatch(finishLoginScatter())

    } catch(err) {
      dispatch(finishLoginScatter(err.message))
      console.error(err)
    }
  }
}

function requestBuyLetter(ltr) {
  return {
    type: SCATTER_REQUEST_BUY_LETTER,
    payload: ltr
  }
}

function finishBuyLetter(errormsg) {
  return {
    type: SCATTER_FINISH_BUY_LETTER,
    payload: errormsg
  }
}

export function buyLetter(ltr) {
  return async (dispatch, getState) => {
    const rpc = new JsonRpc(network.protocol + '://' + network.host + ':' + network.port)
    const eos = ScatterJS.eos(network, Api, { rpc, expireInSeconds: 60 })

    const letter = getState().eos.letters.find((elem) => elem.letter === ltr)

    dispatch(requestBuyLetter(ltr))

    try {

      const requiredFields = { accounts: [network] }
      await ScatterJS.login(requiredFields)
      const account = ScatterJS.account('eos')

      dispatch(receiveScatterLoginInfo(account.name))

      const trx = await eos.transact({
        actions: [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: account.name,
            permission: account.authority,
          }],
          data: {
            from: account.name,
            to: network.contract,
            quantity: letter.price, 
            memo: String.fromCodePoint(letter.letter),
          }
        }]
      }, {
        blocksBehind: 3,
        expireSeconds: 30,
      })
      console.log(`Transaction ID: ${trx.transaction_id}`)

      dispatch(loadEosStrings(true))
      dispatch(closeLetterModal())
      dispatch(finishBuyLetter())
    } catch(err) {
      dispatch(finishBuyLetter(err.message ? err.message : err))
      console.error(err)
    }
  }
}

function requestSetUserString(str) {
  return {
    type: SCATTER_REQUEST_SET_USER_STRING,
    payload: str
  }
}

function finishSetUserString(errormsg) {
  return {
    type: SCATTER_FINISH_SET_USER_STRING,
    payload: errormsg
  }
}

export function setUserStringScatter(str, successCallback) {
  return async (dispatch, getState) => {
    const rpc = new JsonRpc(network.protocol + '://' + network.host + ':' + network.port)
    const eos = ScatterJS.eos(network, Api, { rpc, expireInSeconds: 60 })

    dispatch(requestSetUserString(str))
    
    try {

      const requiredFields = { accounts: [network] }
      await ScatterJS.login(requiredFields)
      const account = ScatterJS.account('eos')

      dispatch(receiveScatterLoginInfo(account.name))


      const trx = await eos.transact({
        actions: [{
          account: network.contract,
          name: 'setstring',
          authorization: [{
            actor: account.name,
            permission: account.authority,
          }],
          data: {
            owner: account.name,
            str,
          }
        }]
      }, {
        blocksBehind: 3,
        expireSeconds: 30,
      })
      console.log(`Transaction ID: ${trx.transaction_id}`)

      dispatch(loadEosStrings(true))
      successCallback()
      dispatch(finishSetUserString())

    } catch(err) {
      dispatch(finishSetUserString(err.message ? err.message : err))
      console.error(err)
    }
  }
}


function requestLoginScatter() {
  return {
    type: SCATTER_REQUEST_LOGIN,
    payload: null
  }
}

function finishLoginScatter(errormsg) {
  return {
    type: SCATTER_FINISH_LOGIN,
    payload: errormsg
  }
}

export function clearErrorScatter() {
  return {
    type: SCATTER_CLEAR_ERROR,
  }
}
