import { combineReducers } from "redux"
import LetterModalReducer from "./letterModalReducer"
import StringModalReducer from "./stringModalReducer"
import HowToModalReducer from "./howToModalReducer"
import ScatterReducer from "./scatterReducer"
import EosReducer from "./eosReducer"

const rootReducer = combineReducers({
  letterModal: LetterModalReducer,
  stringModal: StringModalReducer,
  howToModal: HowToModalReducer,
  scatter: ScatterReducer,
  eos: EosReducer
})

export default rootReducer
