import { SCATTER_RECEIVE_LOGIN_INFO, SCATTER_REQUEST_BUY_LETTER, SCATTER_FINISH_BUY_LETTER, SCATTER_FINISH_LOGIN, SCATTER_REQUEST_LOGIN, SCATTER_REQUEST_SET_USER_STRING, SCATTER_FINISH_SET_USER_STRING, SCATTER_CLEAR_ERROR } from '../actions/actionTypes.js'
const defaultState = {
  scatter: null,
  eos: null,
  username: null,
  userString: null,
  buyingletter: null,
  scattererrormsg: null,
  scatterloginerror: null,
  loggingIn: false,
  settingUserString: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case SCATTER_REQUEST_BUY_LETTER: {
      return { 
        ...state,
        buyingletter: action.payload,
        scattererrormsg: null,
      }
    }

    case SCATTER_FINISH_BUY_LETTER: {
      return { 
        ...state,
        buyingletter: null,
        scattererrormsg: action.payload || null,
      }
    }

    case SCATTER_RECEIVE_LOGIN_INFO: {
      return { 
        ...state,
        username: action.payload,
      }
    }

    case SCATTER_REQUEST_LOGIN: {
      return { 
        ...state,
        loggingIn: true,
        scatterloginerror: null
      }
    }

    case SCATTER_FINISH_LOGIN: {
      return { 
        ...state,
        loggingIn: false,
        scatterloginerror: action.payload || null,
      }
    }

    case SCATTER_REQUEST_SET_USER_STRING: {
      return { 
        ...state,
        settingUserString: true,
        scattererrormsg: null
      }
    }

    case SCATTER_FINISH_SET_USER_STRING: {
      return { 
        ...state,
        settingUserString: false,
        scattererrormsg: action.payload || null,
      }
    }

    case SCATTER_CLEAR_ERROR: {
      return {
        ...state,
        scattererrormsg: null,
      }
    }

    default:
      return state;
  }
};
