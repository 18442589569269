import { EOS_REQUEST_LETTERS, EOS_REQUEST_STRINGS, EOS_RECEIVE_LETTERS, EOS_RECEIVE_STRINGS } from '../actions/actionTypes.js'
const defaultState = {
  letters: [],
  strings: [],
  loadingLetters: false,
  loadingStrings: false,
  beforeFirstStringsLoad: true,
  beforeFirstLettersLoad: true,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case EOS_REQUEST_LETTERS: {
      return {
        ...state,
        loadingLetters: true,
      }
    }

    case EOS_RECEIVE_LETTERS: {
      return { 
        ...state,
        letters: action.payload,
        loadingLetters: false,
        beforeFirstLettersLoad: false,
      }
    }

    case EOS_REQUEST_STRINGS: {
      return {
        ...state,
        loadingStrings: true,
      }
    }

    case EOS_RECEIVE_STRINGS: {
      return { 
        ...state,
        strings: action.payload,
        loadingStrings: false,
        beforeFirstStringsLoad: false,
      }
    }

    default:
      return state;
  }
};
