import React, { Component } from 'react';

import { connect } from 'react-redux'
import { closeStringModal, setStringStringModal, setCaretPositionStringModal, insertCharAtCaretStringModal } from '../actions/modalActions'

import { setUserStringScatter } from '../actions/scatterActions'
import { getEosCharactersIOwn } from '../selectors/eosSelectors'

import twemoji from 'twemoji'
import '../bc-emoji.css'
const twemojiOptions = {
  folder: 'svg',
  ext: '.svg',
  className: 'bc-emj'
}

class UserStringModal extends Component {
  submitItem = () => {
    this.props.setUserStringScatter(this.props.string, () => this.props.closeStringModal())
  }

  render() {
    return (
      <div className={"modal" + (this.props.stringModal !== null ? " is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Update string</p>
            <button className="delete" aria-label="close" onClick={ () => this.props.closeStringModal()}></button>
          </header>
          <section className={"modal-card-body" + (this.props.settingUserString === true ? " element is-loading" : "") }>
            <div className="message is-danger" hidden={this.props.scattererrormsg === null}>
              <div className="message-header">Scatter Error</div>
              <div className="message-body">{this.props.scattererrormsg}</div>
            </div>
            <div className="field">
              <label className="label">String
              <div className="control">
                <input className="input" type="text" placeholder="String"
                  value={this.props.string}
                  onChange={(e) => this.props.setStringStringModal(e.target.value)}
                  onMouseUp={(e) => this.props.setCaretPositionStringModal(e.target.selectionEnd)}
                  onKeyUp={(e) => this.props.setCaretPositionStringModal(e.target.selectionEnd)}
                />
              </div>
              </label>
            </div>
            <p style={{ fontSize: '0.9em'}}><strong>Allowed characters</strong> are those you own: <span style={{fontSize: '1.5em'}}>{this.props.charactersIOwn.map( (item) => <button className="button is-small" key={item.letter} onClick={() => this.props.insertCharAtCaretStringModal(String.fromCodePoint(item.letter))} dangerouslySetInnerHTML={{__html: twemoji.parse(String.fromCodePoint(item.letter), twemojiOptions) + ' '}}/>)}</span> <i>(and space key)</i></p>
            <p style={{ fontSize: '0.9em'}}><strong>Note</strong>: You can update your string at most once per minute.  Submitting an empty string will remove it.</p>
            <p style={{ fontSize: '0.9em'}}><strong>Length</strong>: Up to 40 characters long.</p>
          </section>
          <footer className="modal-card-foot">
            <button
              className='button is-success'
              onClick={this.submitItem}
              disabled={this.props.settingUserString === true}
            >
              Submit</button>
            <button
              className="button"
              onClick={ () => this.props.closeStringModal()}
              disabled={this.props.settingUserString === true}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const stringModal = state.stringModal
  let string = ""

  if (stringModal !== null) {
    string = stringModal.string
  }
  
  return {
    stringModal,
    string,
    username: state.scatter.username,
    strings: state.eos.strings,
    scattererrormsg: state.scatter.scattererrormsg,
    settingUserString: state.scatter.settingUserString,
    charactersIOwn: getEosCharactersIOwn(state) || []
  }
}

export default connect(mapStateToProps, { closeStringModal, setStringStringModal, setCaretPositionStringModal, insertCharAtCaretStringModal, setUserStringScatter })(UserStringModal);
