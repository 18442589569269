import { JsonRpc } from 'eosjs'
import { EOS_REQUEST_LETTERS, EOS_REQUEST_STRINGS, EOS_RECEIVE_LETTERS, EOS_RECEIVE_STRINGS } from "./actionTypes";

import network from '../env'

function receiveEosLetters(letters) {
  return {
    type: EOS_RECEIVE_LETTERS,
    payload: letters
  }
}

function receiveEosStrings(strings) {
  return {
    type: EOS_RECEIVE_STRINGS,
    payload: strings
  }
}

export function loadEosStrings(thenLoadEosLetters = false) {
  return (dispatch, getState) => {
    dispatch({type: EOS_REQUEST_STRINGS})

		const rpc = new JsonRpc(
      network.protocol + '://' + network.host + ':' + network.port
    )
    rpc.get_table_rows({
      code: network.contract,
      scope: network.contract,
      table: network.userstringtable,
      limit: -1,
      json: true,
    }).then((res) => {
			dispatch(receiveEosStrings(res.rows.map((item, i) => {
				return {
					owner: item.owner,
					string: item.str
				}	
      })))
      if (thenLoadEosLetters) {
        dispatch(loadEosLetters()) 
      }
    })
  }
}

export function loadEosLetters() {
  return (dispatch, getState) => {
    dispatch({type: EOS_REQUEST_LETTERS})

		const rpc = new JsonRpc(
      network.protocol + '://' + network.host + ':' + network.port
    )
    rpc.get_table_rows({
      code: network.contract,
      scope: network.contract,
      table: network.lettertable,
      limit: -1,
      json: true,
    }).then((res) => {
			dispatch(receiveEosLetters(res.rows.map((item, i) => {
				return {
					letter: item.key,
					price: item.price,
					owner: item.owner
				}	
			})))
    })
  }
}
