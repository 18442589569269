import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closeLetterModal } from '../actions/modalActions';
import { buyLetter } from '../actions/scatterActions';
import { getEosLetterToBuy } from '../selectors/eosSelectors'

import twemoji from 'twemoji'
import '../bc-emoji.css'
const twemojiOptions = {
  folder: 'svg',
  ext: '.svg',
  className: 'bc-emj'
}

export class ModalManager extends Component {
  render() {
    const letter = this.props.letterModal.letter
    return (
      <div className={"modal" + (Object.keys(this.props.letterModal).length !== 0 ? " is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Purchase a character</p>
            <button className="delete" aria-label="close" onClick={ () => this.props.closeLetterModal()}></button>
          </header>
          <section className={"modal-card-body" + (this.props.buyingletter !== null ? " element is-loading" : "")}>
            <div className="message is-danger" hidden={this.props.scattererrormsg === null}>
              <div className="message-header">Scatter Error</div>
              <div className="message-body">{this.props.scattererrormsg}</div>
            </div>
            <ul>
              <li><strong>Character</strong>: <span dangerouslySetInnerHTML={{__html: (letter ? twemoji.parse(String.fromCodePoint(letter), twemojiOptions) : null) }} /></li>
              <li><strong>Price</strong>: {this.props.letterentry.price}</li>
              <li><strong>Owned by</strong>: {this.props.letterentry.owner}</li>
            </ul>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={ () => this.props.closeLetterModal()}
              disabled={this.props.buyingletter !== null}
            >
              Cancel
            </button>
            <button
              className='button is-success'
              onClick={ () => { this.props.buyLetter(this.props.letterModal.letter) } }
              disabled={this.props.buyingletter !== null}
            >
              OK
            </button>
          </footer>
        </div>
      </div>
     )
  }
}

function mapStateToProps(state) {
  return { 
    letterModal: state.letterModal || {},
    buyingletter: state.scatter.buyingletter,
    scattererrormsg: state.scatter.scattererrormsg,
    letterentry: getEosLetterToBuy(state) || {}
  }
}

export default connect(mapStateToProps, { closeLetterModal, buyLetter })(ModalManager);
