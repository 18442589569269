import { createSelector } from 'reselect'

const getLetterModal = (state) => state.letterModal
const getEosLetters = (state) => state.eos.letters

export const getEosLoadingLetters = (state) => state.eos.loadingLetters
export const getEosLoadingStrings = (state) => state.eos.loadingStrings

const getUsername = (state) => state.scatter.username

export const getEosBeforeFirstLettersLoad = (state) => state.eos.beforeFirstLettersLoad
export const getEosBeforeFirstStringsLoad = (state) => state.eos.beforeFirstStringsLoad

export const getEosLetterToBuy = createSelector(
  [getEosLetters, getLetterModal],
  (eosLetters, letterModal) => {
    if (letterModal === null || eosLetters === null) return null
    return  eosLetters.find((item) => item.letter === letterModal.letter)
  }
)

export const getEosCharactersIOwn = createSelector(
  [getEosLetters, getUsername],
  (eosLetters, username) => {
    return  eosLetters.filter((item) => item.owner === username)
  }
)

export const getEosLoading = createSelector(
  [getEosLoadingLetters, getEosLoadingStrings],
  (eosLoadingLetters, eosLoadingStrings) => {
    return eosLoadingLetters || eosLoadingStrings
  }
)

export const getStringModal = (state) => state.stringModal
